import React from 'react';
import withDeviceType from './with-device-type';

function forDevice<T>(
  ComponentMobile: React.ComponentType<T>,
  ComponentDesktop: React.ComponentType<T>,
) {
  return withDeviceType(({ isMobile, isDesktop, ...props }) => {
    const Component = isMobile ? ComponentMobile : ComponentDesktop;
    return <Component {...(props as T)} />;
  }) as React.ComponentType<T>;
}

export default forDevice;
